.work__filters {
    display: flex;
    column-gap: 1.875rem;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 2.5rem;
}
.work__item {
    cursor: pointer;
    font-weight: var(--font-bold);
    transition: .3s;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
}
.work__item:hover {
    color: var(--first-color);
}
.work__container {
    grid-template-columns: repeat(3, 1fr);
    gap: 1.875rem;
}
.work__card{
    position: relative;
    overflow: hidden;
    box-shadow: var(--shadow);
    border-radius: var(--border-radius);
    -webkit-border-radius: var(--border-radius);
    -moz-border-radius: var(--border-radius);
    -ms-border-radius: var(--border-radius);
    -o-border-radius: var(--border-radius);
}
.work__mask{
    background: #6c6ce5;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    opacity: 0;
}
.work__card:hover .work__mask {
    opacity: 0.9;
}
.work__category {
    position: absolute;
    background-color: var(--first-color);
    color: #fff;
    top: 0;
    left: 1.5rem;
    border-bottom-left-radius: 0.98rem;
    border-bottom-right-radius: 0.98rem;
    font-size: var(--small-font-size);
    display: inline-block;
    padding: 0.19rem 0.625rem;
    transform: translateY(-40px);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
}
.work__title {
    color: #fff;
    font-size: var(--h3-font-size);
    margin: 0 0 0.98rem;
    padding: 0 1.25rem;
    position: absolute;
    top: 3.75rem;
    transform: translateY(30px);
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    opacity: 0;
}
.work__button {
    color: #fff;
    position: absolute;
    bottom: 1.5rem;
    left: 1.5rem;
    font-size: var(--h3-font-size);
    display: block;
    background-color: #ffd15c;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    text-align: center;
    line-height: 42px;
    opacity: 0;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
}
.work__card:hover .work__button{
    opacity: 1;
}
.work__card:hover .work__title,
.work__card:hover .work__category{
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
}
@media screen and (max-width: 1024px) {
    .work__filters {
        justify-content: center;
    }
    .work__container {
        grid-template-columns: repeat(2, 330px);
        justify-content: center;
        row-gap: 1.875rem;
    }
}
@media screen and (max-width: 1024px) {
    .work__container {
        grid-template-columns: 310px;
    }
    .work__filters {
        row-gap: .25rem;
    }
}
@media screen and (max-width: 350px) {
    .work__container {
        grid-template-columns: 1fr;
    }
}