.services__container{
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1.875rem;
}
.services__card {
    text-align: center;
    padding: 1.875rem;
    background-color: var(--container-color);
    border-radius: var(--border-radius);
    -webkit-border-radius: var(--border-radius);
    -moz-border-radius: var(--border-radius);
    -ms-border-radius: var(--border-radius);
    -o-border-radius: var(--border-radius);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.services__card:hover{
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
}
.services__card:nth-child(1) {
    background-color: rgb(108, 108, 229);
    box-shadow: 0px 5px 20px 0px rgb(108, 108, 229 / 50%);
}
.services__card:nth-child(2) {
    background-color: rgb(249, 215, 76);
    box-shadow: 0px 5px 20px 0px rgb(249, 215, 76 / 50%);
}
.services__card:nth-child(3) {
    background-color: rgb(249, 123, 139);
    box-shadow: 0px 5px 20px 0px rgb(249, 123, 139 / 50%);
}
.services__img{
    margin-bottom: 1.25rem;
}
.services__title{
    font-size: var(--h3-font-size);
    margin-bottom: 1rem;
    color: #fff;
}
.services__description {
    color: #f8f9fa;
}
.services__card:nth-child(2) .services__title {
    color: var(--title-color);
}
.services__card:nth-child(2) .services__description {
    color: var(--text-color);
}
@media screen and (max-width: 1024px) {
    .services__container {
        grid-template-columns: repeat(2, 330px);
        justify-content: center;
        row-gap: 1.875rem;
    }
}
@media screen and (max-width: 768px) {
    .services__container {
        grid-template-columns: 310px;
        
    }
}
@media screen and (max-width: 350px) {
    .services__container {
        grid-template-columns: 1fr;
    }
}