.home{
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.intro {
    max-width: 540px;
    text-align: center;
    z-index: 1;
}
.home__img{
    margin-bottom: 1.5rem;
}
.home__name{
    font-size: var(--h1-font-size);
    font-weight: var(--font-bold);
    margin-bottom: 0.5rem;
}
.home__socials{
    display: flex;
    column-gap: 1.75rem;
    margin: 1.5rem 0;
}
.home__social-link{
    color: var(--title-color);
    font-size: 1.3rem;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
}
.home__social-link:hover{
    color: hsl(43, 100%, 68%);
}
.scroll__down{
    position: absolute;
    bottom: 2.5rem;
    left: 0;
    width: 100%;
}
.home__scroll-name{
    font-size: var(--small-font-size);
}
.mouse{
    border: 2px solid #454360;
    display: block;
    width: 1.25rem;
    height: 1.6rem;
    margin: auto;
    margin-top: .75rem;
    border-radius: 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
    position: relative;
}
@keyframes ani-mouse {
    0% {
        top: 29%;
    }
    15% {
        top: 50%;
    }
    50% {
        top: 50%;
    }
    100% {
        top: 29%;
    }
}
.wheel{
    background-color: var(--title-color);
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    width: 0.25rem;
    height: .25rem;
    position: absolute;
    top: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    animation: ani-mouse 2s linear;
    -webkit-animation: ani-mouse 2s linear infinite;
}

.shapes{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}
.shape{
    position: absolute;
}
.s1{
    left: 2%;
    top: 10%;
}
.s2{
    left: 18%;
    top: 30%;
}
.s3{
    left: 5%;
    bottom: 30%;
}
.s4{
    left: 2%;
    bottom: 10%;
}
.s5{
    left: 5%;
    bottom: 30%;
}
.s6{
    left: 36%;
    bottom: 10%;
}
.s7{
    top: 20%;
    right: 25%;
}
.s8{
    right: 24%;
    bottom: 20%;
}
.s9{
    right: 2%;
    top: 10%;
}
.s10{
    top: 45%;
    right: 11%;
}
.s11{
    bottom: 10%;
    right: 2%;
}

@media screen and (max-width: 1024px) {
    .home__social-link {
        font-size: 1.125rem;
    }
}